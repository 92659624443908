@import 'components/mixins.scss';

.cui {
  &__layout {
    &__header {
      background: $white !important;
    }

    /////////////////////////////////////////////////////////////////////////////////////////
    /* SETTINGS */
    &__grayBackground {
      background: $gray-1;
    }
    &__contentMaxWidth {
      .cui__utils__content {
        max-width: 1420px;
        margin: 0 auto;
      }
    }
    &__appMaxWidth {
      max-width: 1420px;
      margin: 0 auto;
    }
    &__squaredBorders {
      .card {
        border-radius: 0 !important;
      }
    }
    &__cardsShadow {
      .card {
        box-shadow: $shadow-4;
        .card {
          box-shadow: none;
        }
      }
    }
    &__borderless {
      .card {
        border: none !important;
      }
    }
    &__fixedHeader {
      position: sticky;
      top: 0;
      z-index: 998;
    }
    &__headerGray {
      background: $gray-1 !important;
    }
  }
}

// dark theme
[data-kit-theme='dark'] {
  .cui {
    &__layout {
      &__grayBackground,
      &__headerGray {
        background: transparent !important;
      }
    }
  }
}

// .back-button {
//   float: right !important;
//   padding: 8px !important;
//   margin: 0 1rem !important;
// }

th.ant-table-cell {
  font-size: 12px;
}

td.ant-table-cell {
  // padding: 0px 8px;
  button.ant-btn.ant-btn-link {
    font-size: 12px;
    font-weight: 400;
    padding: 0;
    margin: 0;
    height: 0;
    & span:hover {
      text-decoration: underline;
    }
  }
}

.ant-table-tbody > tr > td {
  padding: 3px 4px !important;
}

.ant-table-thead > tr > th {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  white-space: normal;
}

[data-kit-theme='default'] .ant-modal {
  top: 30px !important;
  height: 90vh;
}

[data-kit-theme='default'] .ant-modal-centered .ant-modal {
  top: -28px;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

[data-kit-theme='default'] .ant-modal-header {
  padding: 12px 24px;
  color: #595c97;
  background: #ffffff;
  border-bottom: 1px solid #e4e9f0;
  border-radius: 2px 2px 0 0;
}

[data-kit-theme='default'] .ant-modal-body {
  padding: 12px;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
  padding-top: 0px;
}

[data-kit-theme='default'] #exceptiondetails.ant-table .ant-table-selection-col {
  width: 30px;
}

[data-kit-theme='default'] .ant-table-cell .ant-typography-copy {
  position: absolute;
  right: 4px;
}

.horizontal-input {
  display: flex;
}

.react-resizable-handle {
  background: #ffffea !important;
}

.searchtable > div:nth-child(1) > div:nth-child(1) {
  justify-content: end !important;
}

.searchtable .ant-input-affix-wrapper {
  width: 30% !important;
}

[data-kit-theme='default'] .ant-steps-item-title {
  font-size: 25px;
}
