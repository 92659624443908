@import 'components/mixins.scss';

.toggleButton {
  font-size: 18px;
  height: rem(40);
  padding: rem(10) rem(20);
  background: $white;
  border: none;
  box-shadow: 0 5px 14px rgba(0, 0, 0, 0.1) !important;
  font-weight: bold;
  border-radius: 30px;
  color: $primary;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: $primary;
    color: $white;
  }

  span {
    font-size: 16px;
    vertical-align: top;
  }
}

.chat {
  position: fixed;
  z-index: 998;
  bottom: rem(45);
  right: rem(40);
  @include transition-slow();
}

.container {
  display: flex;
  flex-direction: column;
  z-index: -1;
  transform: translateY(10px);
  opacity: 0;
  visibility: hidden;
  position: absolute;
  bottom: calc(100% + 20px);
  right: 0;
  background-color: $white;
  width: rem(330);
  border-radius: 5px;
  box-shadow: 0 5px 20px -5px rgba($black, 0.08), 0 5px 20px -5px rgba($black, 0.08);
  padding-left: rem(25);
  padding-right: rem(25);
  padding-top: rem(15);
  padding-bottom: rem(10);
  @include transition-slow();

  @media (max-width: $sm-max-width) {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  &::before {
    content: '';
    position: absolute;
    top: 100%;
    right: rem(52);
    width: 0;
    height: 0;
    border-top: 7px solid white;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 0;

    @media (max-width: $md-max-width) {
      right: rem(10);
    }
  }
}

.containerToggled {
  z-index: 10;
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.innerContainer {
  height: rem(300);

  @media (max-width: $sm-max-width) {
    flex-grow: 1;
  }
}

.icon {
  position: relative;
  bottom: -1px;
}

// dark theme
[data-kit-theme='dark'] {
  .container {
    background: $dark-gray-4;

    &::before {
      border-top: 7px solid $dark-gray-4;
    }
  }
  .toggleButton {
    background: $dark-gray-4;
  }
}

// for chat component
@import 'components/mixins.scss';

.closeBtn {
  padding: 0;
  border: none;
}

.contentWrapper {
  padding-right: 15px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.message {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: rem(15);
  overflow: hidden;
  flex-shrink: 0;

  &.answer {
    flex-direction: row-reverse;

    .messageAvatar {
      margin-left: 0;
      margin-right: rem(15);
    }

    .messageContent {
      &::before {
        left: auto;
        right: 100%;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-right: 4px solid $gray-1;
        border-left: none;
      }
    }
  }
}

.messageAvatar {
  flex-shrink: 0;
  margin-left: rem(15);
}

.messageContent {
  background-color: $gray-1;
  position: relative;
  border-radius: 5px;
  padding-left: rem(15);
  padding-right: rem(15);
  padding-top: rem(6);
  padding-bottom: rem(6);

  &::before {
    content: '';
    position: absolute;
    left: 100%;
    bottom: 16px;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 4px solid $gray-1;
    border-right: none;
  }
}

// dark theme
[data-kit-theme='dark'] {
  .messageContent {
    background-color: $dark-gray-5;
    &:before {
      border-left-color: $dark-gray-5;
    }
  }
  .message {
    &.answer {
      .messageContent {
        &:before {
          border-right-color: $dark-gray-5;
        }
      }
    }
  }
}
