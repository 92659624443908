@import 'components/mixins.scss';

.cui {
  &__utils {
    &__content {
      padding: rem(30);
      margin: 0 auto;

      @media (max-width: $sm-max-width) {
        padding: rem(30) rem(16);
      }
    }

    &__heading {
      color: $black;
      font-size: rem(17);
      text-transform: uppercase;
      margin-bottom: rem(12);
    }

    &__shadow {
      box-shadow: $shadow-ant;
    }
  }
}

// dark theme
[data-kit-theme='dark'] {
  .cui {
    &__utils {
      &__heading {
        color: $dark-gray-1;
      }
    }
  }
}

[data-kit-theme='default'] .ant-table-thead > tr > th {
  color: #595c97;
  font-weight: 600;
  text-align: left;
  // background: #f0f2f4;
  // background: $gray-3 !important;
  background: #fcceb8 !important;
  border-bottom: 2px solid #e4e9f0;
  // border-bottom: 1px solid #000000;
  transition: background 0.3s ease;
}
